import styles from "./MenuHamburger.module.scss";
import cx from "classnames";
const View = ({ show = false, className, ...props }: any) => {
  return (
    <label
      {...props}
      className={cx(styles.container, className, { [styles.show]: show })}
    >
      <div className={styles.checkmark}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </label>
  );
};
export default View;

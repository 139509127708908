import React from "react";
import { useModalContext } from "@providers/ModalProvider";
import Expand from "@components/common/Expand";
import Modal from "@components/common/Modal";
import Link from "@components/common/Link";
import MenuHamburger from "@components/common/MenuHamburger";

import cx from "classnames";
import styles from "./MenuModal.module.scss";
import { get, map } from "@utils/lodash";

const View = ({ NAVS }: any) => {
  const [show, $show] = React.useState(false);
  const { modalService } = useModalContext();
  // const { i18n } = useTranslationContext();
  const handler = {
    onClose: () => modalService.removeDOM(400),
  };
  React.useEffect(() => {
    // debounce(() => {
    $show(!!modalService.visible);
    // }, 50)();
  }, [get(modalService, "visible")]);

  return (
    <Modal
      style={{
        content: { inset: 0, transform: "initial", marginRight: 0, padding: 0 },
      }}
      isOpen={modalService.visible}
    >
      <div>
        <div className={cx("inner", styles.header)}>
          <MenuHamburger
            show={show}
            className={styles.menu}
            onClick={handler.onClose}
          />
        </div>

        {map(NAVS, (nav: any, index: number) => {
          const hasChild = !!get(nav, "children");
          return (
            <div
              className={cx("flex flex-col gap-2", styles.nav)}
              key={`nav_${index}`}
            >
              <Expand>
                <Expand.Title>
                  <Link
                    href={nav.link}
                    className={cx(styles.label)}
                    target={nav.target}
                  >
                    {nav.title}
                  </Link>
                </Expand.Title>
                <Expand.Description>
                  {hasChild && (
                    <div className="pv-child-nav pb-4">
                      {map(get(nav, "children"), (child, j) => (
                        <Link
                          key={`nav_${index}_${j}`}
                          className="px-4 text-white"
                          href={child.link}
                        >
                          {child.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </Expand.Description>
              </Expand>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
export default View;

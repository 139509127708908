import React, { useContext, createContext } from "react";
import Show from "@components/common/Show";
import { isEqual, get, isUndefined, isFunction } from "lodash";
import { animated, useSpring, config } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { match } from "utils/renderPropsComposer";
import cx from "classnames";
interface AppExpandContext {
  isExpanded?: boolean;
  [key: string]: any;
}
const ExpandContext = createContext<AppExpandContext>({
  isExpanded: false,
});
interface TypeUseExpandContext {
  (): AppExpandContext;
}

export const Collapse = ({
  children,
  expanded = false,
  defaultExpanded = false,
  showFirstLine = false,
  lineHeight = 36,
  duration = 50,
  getAnimatedStyle = (value: any) => ({}),
}) => {
  const [isAminated, $isAminated] = React.useState<boolean>(false);
  const [measureRef, { height }] = useMeasure();
  const [staticStyles, $staticStyles]: any = React.useState({
    overflow: "visible",
    visibility: "hidden",
  });
  const updateStaticStyles =
    (started = false) =>
    () =>
      $staticStyles({
        overflow: !started && expanded ? "visible" : "hidden",
        visibility: started || (!started && expanded) ? "visible" : "hidden",
      });
  const animatedStyles = useSpring({
    config: { tension: 350, mass: 0.6 },
    immediate: !isAminated,
    from: {
      height: 0,
    },
    to: {
      height: expanded ? height || 0 : 0,
    },
    onStart: updateStaticStyles(true),
    onRest: updateStaticStyles(false),
  });
  React.useEffect(() => {
    if (!isAminated) {
      $isAminated(true);
    }
  }, []);
  return (
    <animated.div
      style={{
        ...staticStyles,
        ...animatedStyles,
      }}
      className={"origin-top"}
    >
      <div ref={measureRef}>{children}</div>
    </animated.div>
  );
};
const Comp = ({
  name = "",
  children,
  isExpand = undefined,
  defaultExpanded = false,
  onExpand = undefined,
  showFirstLine = false,
  lineHeight = 30,
  duration = 300,
  containerStyle = {},
}) => {
  const ref: any = React.useRef({});
  const [expanded, $expanded] = React.useState(defaultExpanded);

  const isExpanded = !isUndefined(isExpand) ? isExpand : expanded;
  const handleChangeExpand = !isUndefined(onExpand) ? onExpand : $expanded;

  const handler = {
    onClickTitle: (value) => {
      ref.current.isAnimated = true;
      handleChangeExpand(value);
    },
    getAnimatedStyle: (animatedStyles) => {
      return ref.current.isAnimated ? animatedStyles : {};
    },
  };
  return React.Children.map(children, (Child) => {
    return (
      <ExpandContext.Provider value={{ isExpanded }}>
        <Show>
          <Show.When isTrue={isEqual(get(Child, "type.displayName"), "Title")}>
            <div
              className="cursor-pointer"
              onClick={() => {
                handler.onClickTitle(!isExpanded);
                if (isFunction(Child.props.onClick))
                  Child.props.onClick(!isExpanded);
              }}
            >
              {Child}
              {/* {<Child.type {...Child.props} expanded={isExpanded} />} */}
            </div>
          </Show.When>
          <Show.When
            isTrue={isEqual(get(Child, "type.displayName"), "Description")}
          >
            <Collapse
              // defaultExpanded={isExpanded}
              expanded={isExpanded}
              showFirstLine={showFirstLine}
              lineHeight={lineHeight}
              duration={duration}
              getAnimatedStyle={handler.getAnimatedStyle}
            >
              {Child}
            </Collapse>
          </Show.When>
        </Show>
      </ExpandContext.Provider>
    );
  });
};
export const useExpandContext: TypeUseExpandContext = () => {
  return useContext(ExpandContext);
};
const Title = ({ children }: any) => children;
Title.displayName = "Title";
const Description = ({ children }: any) => children;
Description.displayName = "Description";

Comp.Title = Title;
Comp.Description = Description;

export default Comp;

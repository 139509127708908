"use client";
import React from "react";
import { useTheme } from "next-themes";
import gs from "@gstyles/index";
export default function Switcher() {
  const { theme, setTheme } = useTheme();
  return (
    <button
      className="ml-4 text-black"
      onClick={(e) => (theme === "dark" ? setTheme("light") : setTheme("dark"))}
    >
      {theme === "dark"
        ? gs.icons({ name: "sun-solid", size: 30 })
        : gs.icons({ name: "moon-solid", size: 30 })}
    </button>
  );
}

export const NAVS = [
  {
    title: "Trang Chủ",
    link: "/",
  },
  {
    title: "Chơi Đồ",
    link: "/san-pham",
  },
  {
    title: "Học Tập",
    link: "#",
    children: [
      {
        title: "React Native",
        link: "/hoc-tap/rn/gioi-thieu",
      },
    ],
  },
  {
    title: "Về Tôi",
    link: "/me",
  },
  // {
  //   title: "CV",
  //   link: "https://ehphuong.page.link/cv",
  //   target: "_blank",
  // },
];

export const YOUTUBE_STATUS = {
  TRANSCRIPT_FETCHED: "TRANSCRIPT_FETCHED",
  RETRY_TRANSCRIPT_FETCHED: "RETRY_TRANSCRIPT_FETCHED",
  SUMMARIZED: "SUMMARIZED",
  RETRY_SUMMARIZED: "RETRY_SUMMARIZED",
};
export const YOUTUBE_STATUS_OPTIONS = [
  {
    label: "Transcript Fetched",
    value: YOUTUBE_STATUS.TRANSCRIPT_FETCHED,
  },
  {
    label: "Transcript Fetching",
    value: YOUTUBE_STATUS.RETRY_TRANSCRIPT_FETCHED,
  },
  {
    label: "Summarized",
    value: YOUTUBE_STATUS.SUMMARIZED,
  },
  {
    label: "Summarizing",
    value: YOUTUBE_STATUS.RETRY_SUMMARIZED,
  },
];

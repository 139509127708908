import Logo from "@components/common/Logo";
import Link from "@components/common/Link";
import { NAVS } from "@utils/constants";
import Switcher from "./Switcher";

import ModalProvider, {
  BoxModal,
  useModalContext,
} from "@providers/ModalProvider";
import MenuModal from "./MenuModal";
import styles from "./Header.module.scss";
import MenuHamburger from "@components/common/MenuHamburger";

import cx from "classnames";
const View = ({ className }) => {
  const { modalService } = useModalContext();
  return (
    <div className={cx(styles.headerMain, className)}>
      <div className={styles.left}>
        <Link href={"/"} className="no-underline">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <div className={styles.center}>
        {/* <Link href={"/"} className="no-underline">
                  <Logo className={styles.logo} />
                </Link> */}
      </div>
      <div className={styles.right}>
        <Switcher />
        <BoxModal
          modal={<MenuModal NAVS={NAVS} />}
          Comp={(props) => {
            return (
              <>
                <MenuHamburger
                  {...props}
                  show={modalService.visible}
                  className={styles.menu}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
};
export default View;

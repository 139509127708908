import cx from "classnames";
import Modal from "react-modal";
const View = ({ className, style = {}, ...props }: any) => {
  const { content, ..._style } = style;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      ...content,
    },
    ..._style,
  };
  return (
    <Modal
      {...props}
      style={customStyles}
      closeTimeoutMS={500}
      className={cx({
        [`top-1/2 left-1/2 -mr-1/2 -translate-2/4 absolute bg-white rounded outline-none p-5 ${className}`]:
          !!className,
      })}
    />
  );
};

export default View;

import React, { createContext, useContext } from "react";
import {
  AppModalContext,
  AppModalProps,
  TypeModalService,
} from "@type/provider/Modal";
import CompButton from "@components/common/Button";
import { debounce, isFunction, isNumber } from "@utils/lodash";
export const ModalContext = createContext<AppModalContext>({
  modalService: {
    visible: false,
    show: () => {},
    hide: () => {},
    addDOM: () => {},
    removeDOM: () => {},
    setWidth: () => {},
  },
});

interface TypeUseModalContext {
  (): AppModalContext;
}

const ModalProvider = ({ children }: AppModalProps): any => {
  const [renderModal, $renderModal]: any = React.useState();
  const [visible, $visible] = React.useState(false);
  const [width, $width] = React.useState("700px");
  const modalService: TypeModalService = {
    visible,
    width,
    show: () => {
      $visible(true);
    },
    hide: () => {
      $visible(false);
    },
    addDOM: (render: any) => {
      $renderModal(render);
      modalService.show();
    },
    removeDOM: (delay = 400, callback?: any) => {
      const _delay = isNumber(delay) ? delay : 400;
      modalService.hide();
      debounce(() => {
        $renderModal(false);
        isFunction(callback) ? callback() : null;
      }, _delay)();
    },
    setWidth: (value: string) => {
      $width(value);
    },
  };

  return (
    <ModalContext.Provider value={{ modalService }}>
      {(() => {
        return isFunction(renderModal) ? renderModal() : renderModal;
      })()}
      {children}
    </ModalContext.Provider>
  );
};
export const useModalContext: TypeUseModalContext = () => {
  return useContext(ModalContext);
};

export const ButtonModal: React.FC<{
  style?: any;
  modal: any;
  onClick?: () => void;
  children: React.ReactNode;
}> = ({ modal, onClick, ...props }: any) => {
  const { modalService } = useModalContext();
  const _onClick = (e: any) => {
    if (isFunction(onClick)) onClick(e);
    modalService.addDOM(() => (isFunction(modal) ? modal() : modal));
  };
  return <CompButton onClick={_onClick} {...props} />;
};

export const BoxModal: React.FC<{
  style?: any;
  modal: any;
  onClick?: () => void;
  children?: React.ReactNode;
  Comp: (props: any) => React.ReactNode;
}> = ({ modal, onClick, Comp, ...props }: any) => {
  const { modalService } = useModalContext();
  const _onClick = (e: any) => {
    if (isFunction(onClick)) onClick(e);
    modalService.addDOM(() => (isFunction(modal) ? modal() : modal));
  };
  return isFunction(Comp) ? Comp({ onClick: _onClick, ...props }) : Comp;
};

export default ModalProvider;

import { Children, ReactNode } from 'react';
const Comp = (props: any) => {
  let when: null | ReactNode = null;
  let otherwise = null;
  Children.forEach(props.children, children => {
    if (children.props.isTrue === undefined && !!children) {
      otherwise = children;
    } else if (!when && children.props.isTrue === true && !!children) {
      when = children;
    }
  });
  return when || otherwise;
};
Comp.When = ({ isTrue, children }: any) => {
  return isTrue && children;
};
Comp.Else = ({ render, children }: any) => render || children;
export default Comp;

import Logo from "@components/common/Logo";
import Link from "@components/common/Link";
import { NAVS } from "@utils/constants";
import Switcher from "./Switcher";
import styles from "./Header.module.scss";
import { map, get } from "lodash";
import cx from "classnames";
import { useTheme } from "next-themes";
const View = ({ className }) => {
  const { theme } = useTheme();
  return (
    <div className={cx(className, styles.headerMain)}>
      <div className={styles.left}>
        <Link href={"/"} className="no-underline">
          <Logo className={styles.logo} />
        </Link>
      </div>
      <div className={styles.center}>
        {/* <Link href={"/"} className="no-underline">
                  <Logo className={styles.logo} />
                </Link> */}
      </div>
      <div className={styles.right}>
        {map(NAVS, (nav: any, index: number) => {
          const hasChild = !!get(nav, "children");
          return (
            <div className={"pv-nav"} key={`nav_${index}`}>
              <Link
                href={nav.link}
                className={cx("pv-label", styles.nav)}
                target={nav.target}
              >
                {nav.title}
              </Link>
              {hasChild && (
                <div className="pv-child-nav ">
                  {map(get(nav, "children"), (child, j) => (
                    <Link
                      key={`nav_${index}_${j}`}
                      className="px-4 text-white"
                      href={child.link}
                    >
                      {child.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          );
        })}
        <Switcher />
      </div>
    </div>
  );
};
export default View;
